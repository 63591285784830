<template>
  <v-container id="login" class="fill-height justify-center" tag="section">
    <v-row justify="center">
      <v-slide-y-transition appear>
        <base-material-card
          color="success"
          light
          max-width="100%"
          width="400"
          class="px-5 py-3"
        >
          <template v-slot:heading>
            <div class="text-center">
              <h1 class="display-2 font-weight-bold mb-2">Connexion</h1>
            </div>
          </template>

          <v-card-text class="text-center">
            <div class="text-center grey--text body-1 font-weight-light">
              Administration Respiro
            </div>

            <v-text-field
              v-model="courriel"
              color="secondary"
              label="Usager"
              prepend-icon="mdi-face"
              class="mt-10"
              data-cy="tfUsager"
            />

            <v-text-field
              v-model="motPasse"
              type="password"
              class="mb-8"
              color="secondary"
              label="Mot de passe"
              prepend-icon="mdi-lock-outline"
              data-cy="tfMotPasse"
            />

            <pages-btn
              large
              color=""
              depressed
              class="v-btn--text success--text"
              @click="onConnexion()"
            >
              Connecter
            </pages-btn>
          </v-card-text>
        </base-material-card>
      </v-slide-y-transition>
    </v-row>
  </v-container>
</template>

<script>
import opusService from "@/services/OpusService.js";

export default {
  name: "PagesLogin",

  components: {
    PagesBtn: () => import("../pages/components/Btn"),
  },

  data: () => ({
    courriel: "",
    motPasse: "",
  }),
  methods: {
    onConnexion() {
      opusService
        .connexion(this.courriel, this.motPasse)
        .then((result) => {
          this.$router.push({ path: "/ecrans/gestionScenarios" });
        })
        .catch((erreur) => {
          this.$dialog.alert("Authentification invalide");
        });
    },
  },
};
</script>
