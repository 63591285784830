import restApiService from '@/services/restApiService.js'
import store from '../store'

const OpusService = {
  connexion (usager, motPasse) {
    const info = {
      username: usager,
      password: motPasse,
      rememberMe: false,
    }
    store.commit('SET_JWTTOKEN', '')

    return restApiService
      .post('/api/usagers/authentifierAdmin', info)
      .then((result) => {
        store.commit('SET_JWTTOKEN', result.data.id_token)
        return restApiService.get('/api/usagers/connecte').then((result) => {
          store.commit('SET_USAGER', result.data)
        })
      })
  },
  déconnexion () {
    store.commit('SET_USAGER', null)
    store.commit('SET_JWTTOKEN', null)
  },
  api () {
    return restApiService
  },
}

window.opusService = OpusService
export default OpusService
